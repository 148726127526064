.preview-study-info {
    background: var(--sidebar-background);
    box-shadow: var(--shadow);
    color: var(--text-color);
    // height: calc(100vh - var(--top-height));
    // margin-left: 10px;
    position: relative;
    // right: -20px;
    // max-width: 300px;
    // margin: -20px -20px -20px 20px;
    // margin-top: -20px;
    // margin-right: -10px;
    &.show {
        width: var(--preview-width);
    }
}

.studies-custom-table {
    flex: 1 1 !important;
    min-width: 0;
    // width: 100%;
    // min-width: calc(100vw - 240px - 500px + 20px);
}

.header-icon{
    color: #fff !important;
}

.height-study-info{
    height: calc(100vh - var(--top-height));
}

.content-patient-info{
    // height: calc(100vh - var(--top-height) - 20px - 32px - 190px);
    height: calc(72% - 32px);
    overflow-y: auto;
    // background: var(--sidebar-background);
}

.study-history{
    max-height: 28% !important;
    // border-left: 1px solid var(--border-color);
    & .content-study-history{
        max-height: 100%;
    }
}

// .content-study-history{
//     max-height: calc(100% - 34px) !important;
//     // height: 100%;
// }

.ul-style-type-none{
    list-style-type: none;
    li {
        &:hover{
            color: #346882;
        }
    }
    .active{
        // color: white !important;
        // background-color: #346882 !important;
        font-weight: 500;
        text-decoration: underline;
    }
}

.btn-itech-icon-table{
    height: 26px;
    width: 26px;
    padding: unset !important;
    margin: 0 auto;
    border-radius: 99px !important;
    display: flex;

    svg, i {
        margin: auto;
    }
}

.min-h-0 {
    min-height: 0 !important;
}

.folder-tree {
    .active {
        font-weight: 500;
        color: var(--text-color);
        background-color: var(--text-highlight-color);
    }

    .more-hover {
        visibility: hidden;
    }

    .rstcustom__node:hover .more-hover {
        visibility: visible;
    }
}

.priority-study{
    color: red;
    border: 1px solid;
    border-radius: 15px;
    padding: 2px;
}

.vertical-text{
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    line-height: 2;
    font-size: 14px;
}