@import "../abstracts/variables";

.sidebar-custom {
  --sidebar-text: var(--text-color);
  // --sidebar-background: white;
  --main-color-hover: var(--grey-1);
  --sidebar-text-selected: var(--text-color);
  // --sidebar-item-height: 30px;
  background-color: var(--sidebar-background);
  // font-weight: bold;
  font-size: .875rem;
  height: calc(60% - 32px);

  & .toggle-collapse {
    --sidebar-text: white;
  }
}



/*.main-page {
  .tab-content {
    position: absolute;
    top: $top-height;
    width: 100%;
    //min-height: 100vh;
    height: calc(100% - #{$top-height});

    & .tab-pane {
      height: 100%
    }
  }
}*/

