@import "../abstracts/variables";

.study-summary-info {
	display: flex;
	flex-direction: column;

	> label {
		color: $text-color;
	}

	> div {
		background-color: $text-color;
		color: $white;
		padding: .25rem;
		min-height: 40px;
		border-radius: .25rem;
		width: 100%;
	}
}

.dashboard-label-uppercase{
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
    font-style: normal;
    color: #3E935A;
}

.dashboard-label-uppercase-0875rem{
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
    font-style: normal;
}

.bar-tooltip {
	//position: absolute;
	background-color: white;
	color: $text-color;
	transform: translateX(-50%);
	display: block;
}

@media screen and (max-width: 1200px) {
	.h-max-xl-auto {
		height: auto;
	}
	.w-max-xl-100 {
		width: 100%
	}
}

@media screen and (max-width: 1200px) {
	.total-readed-study {
		//position: absolute;
		//top: 510px;
		flex-direction: row;
		width: 100%;
		margin-left: auto;
		align-items: center;
	}
}

@media screen and (min-width: 1201px) {
	.total-readed-study {
		flex-direction: column;
	}
}

.number-studies-today {
	font-size: 2.4rem;
	width: 120px;
	background-color: var(--main-color-dark);
	color: var(--default-text);
	border-radius: 20px;
	font-weight: 700 !important;
}

.label-number-studies-today {
	font-size: 18px;
	font-weight: 700 !important;
	margin-left: 20px;
}

.info-title-label-dashboard {
	font-size: 20px;
	border-bottom: 3px solid var(--main-color-dark);
	width: 100%;
	text-align: center;
	text-transform: uppercase;
}