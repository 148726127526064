.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background-color: #E3ECF3!important;
  border-color: #E3ECF3!important;
  border-radius: 0%;
  i {
    color: #333547;
    border: #333547;
  }
  span {
    color: #333547;
    font-weight: 600;
  }
}
.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color:#E3ECF3;
  border-color: #333547;
  box-shadow: none;
  i {
    color: #333547;
    border: #333547;
  }
  span {
    color: #333547;
    font-weight: 600;
  }
}