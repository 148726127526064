.vu-meter{
    position: absolute;
    opacity: 0.3;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    max-width: 100%;
    z-index: 4;
    background-color: black;
    transition: width 0.1s;
}
