@import "../abstracts/variables";

.patient-info {
	width: 100%;
	// background: var(--sidebar-background);
	// color: var(--sidebar-background-contrast);
	// filter: brightness(110%);
	// box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

	& .patient-name {
		padding: 15px 30px 12px 20px;
		background: $text-color;

		.label-filter1 {
			color: $white;
			padding-bottom: 0;
			font-size: 14px;
		}

		.label-name {
			color: $white;
			font-size: 22px;
			padding-top: 0;
			margin: 0
		}
	}

	& .basic-info {
		padding: 15px 30px 12px 20px;
		background: $white;
		height: 100%;
	}

	& div {
		border-radius: 0;
	}
}

.study-info {
	.label-info {
		font-size: 14px;
	}
}

.order-stt {
	display: flex;
	.div-order-stt {
		height: 50px;
		width: 75px;
		background: var(--border-color);
	}

	.label-order-stt {
		line-height: 2;
		font-size: 25px;
		width: 100%;
		text-align: center;
	}
}