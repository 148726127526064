.button-group-vietrad{
    max-width: 60px;
    width: 60px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    .button-vietrad {
        min-width: 30px;
        max-width: 30px;
        height: 30px;
        padding: 0;
        border-color: var(--main-background);
        background: var(--button-color-secondary);
        // color: var(--button-color-secondary-contrast);
        color: #fff;
        >i {
            min-width: 16px;
            min-height: 16px;
        }
    }
    span{
        font-size: 10px;
    }
    .button-vietrad:hover {
        background-color: var(--main-color-dark);
    }
}

.button-portal {
    padding: 10px;
    font-size: 13px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--button-color-primary);
    i {
        font-size: 18px;
    }
    span {
        text-transform: uppercase;
    }
    &:hover {
      background-color: #E3ECF3;
      border: #E3ECF3;
      i {
        margin-top: 1px;
        color: #333547;
        border-color:#333547;
        border-radius: 1px;
      }
      span {
        color: #333547;
        font-weight: 600;
      }
    }
    
}