@import "src/assets/scss/abstracts/variables";

.study-show-tab{
    width: 500px !important;
    a[role=tab]{
        background: #f7f7f7 !important;
        // border: 0.5px solid !important;
        // border-radius: 20px 20px 0 0;
        color: #2E3E47;
        font-size: 14px;
        line-height: 14px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        &.active {
            background: #f1f1f1 !important;
        }
    }
    a[role=tab][aria-selected=true]{
        color: #3E935A !important;
        font-size: 14px;
        line-height: 14px;
    }

    a[data-rb-event-key=local] {
        border: none !important;
        border-right: solid 1px !important;
    }

    a[data-rb-event-key=tele] {
        border: none !important;
    }
}

// .body-tab-width{
    // width: 500px !important;
    // height: 100% !important;
// }

.study-info-viewer{
    // width: 500px;
    width: 100%;
    a[role=tab]{
        background: #f7f7f7 !important;
        // border: 0.5px solid !important;
        // border-radius: 20px 20px 0 0;
        color: #2E3E47;
        font-size: 14px;
        line-height: 14px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        &.active {
            background: #f1f1f1 !important;
        }
    }
    a[role=tab][aria-selected=true]{
        color: #3E935A !important;
        font-size: 14px;
        line-height: 14px;
    }

    a[data-rb-event-key=local] {
        border: none !important;
        border-right: solid 1px !important;
    }

    a[data-rb-event-key=tele] {
        border: none !important;
    }
}

.size-select-input {
    width: 200px !important;
}

.size-select-input-400 {
    width: 400px !important;
}

.sidepanel .tab-content {
    height: calc(100% - 31px);
    overflow-y: auto;
}

.sidepanel.from-right.is-open {
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
    @media only screen and (min-width: 768px) {
        max-width: var(--right-sidepanel-menu-width);
    }
}

.d-block .tab-content {
    height: calc(100% - 31px);
}

// .scroll-report-viewer{
//     overflow: auto;
// }

.btn-fixed-100{
    min-width: 100px;
}

#form-pdf > div:first-child{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

#form-pdf .card-body{
    padding: 0px !important;
}

@media (min-width: 768px) {
    .radiologist-info-width {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .radiologist-info-width {
        width: calc(100vw - var(--preview-width) - 70px);
    }
}

.btn-custom{
    border-radius: 20px !important;
	font-weight: 500;
	box-shadow: $shadow;
    white-space: nowrap;
    
    &-view-image{
        background: #2E3E47 !important;
        color: $white !important;
        
        &:hover, &:active {
			background: #495057 !important;
		}
    }

    &-print-pdf{
        background: var(--main-color-dark)!important;
        color: $white !important;
        
        &:hover, &:active {
			background: var(--main-color-hover) !important;
		}
    }

    &-receive-study{
        background: var(--main-color-dark)!important;
        color: $white !important;

        &:hover, &:active {
            background:var(--main-color-hover) !important;
        }
    }

    // &-print-pdf{
    //     background: #bd9453 !important;
    //     color: $white !important;
        
    //     &:hover, &:active {
	// 		background: #e8bb78 !important;
	// 	}
    // }
}

.menu-order {
    label[id^="procedure"].active {
        // color: white !important;
        // background-color: #346882 !important;
        font-weight: 500;
        text-decoration: underline;
    }
    label[id^="procedure"].mobile-active {
        color: white !important;
        background-color: var(--cyan-lighter) !important;
    }
}

// checked image for print report
.print-image-attachment {
    input[type="checkbox"][id^="cb"] {
        display: none;
    }
    
    label {
        border: 1px solid #fff;
        display: block;
        position: relative;
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    
    label::before {
        background-color: white;
        color: white;
        content: " ";
        display: block;
        border-radius: 50%;
        border: 1px solid #376B51;
        position: absolute;
        top: -5px;
        right: -5px;
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 28px;
        transition-duration: 0.4s;
        transform: scale(0);
    }
    
    label[id^="cb"] img {
        height: 100px;
        width: 100px;
        transition-duration: 0.2s;
        transform-origin: 50% 50%;
    }
    
    :checked+label {
        border-color: #ddd;
    }
    
    :checked+label::before {
        content: "✓";
        background-color: #376B51;
        transform: scale(1);
        z-index: 100;
    }
    
    :checked+label img {
        transform: scale(0.9);
        box-shadow: 0 0 5px #333;
        z-index: -1;
    }
}

.btn-delete-attachment-mobile {
	position: absolute;
	top: 0;
	// right: .5rem;
	color: grey;
    padding: .2rem;
    font-size: 25px;

	&:hover, &:active {
		color: black;
	}
}

.report-panel-build-height {
    height: calc(100% - 32px)
}