@import '../abstracts/variables';

.table-link {
	@include shadow-1;

	thead.table-itech-thread {
		tr {
			color: var(--text-color);
			text-transform: uppercase;
			th {
				font-weight: 300 !important;
				text-align: center;
				border-top-width: 1px;
			}
		}

		th {
			border-top-width: 1px;
			padding: 10px;
		}
		td,
		span {
			color: var(--text-color) !important;
			font-weight: 500 !important;
			font-size: .75rem;
		}
	}
	tbody {
		padding: 10px;
		tr {
			&:nth-of-type(odd) {
				background: var(--table-odd);

				&:hover {
					&:not(.active) {
						background: var(--table-odd) !important;
					}

					box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
				}
			}

			&:nth-of-type(even) {
				&:not(.active) {
					background:var(--table-even) !important;
				}

				&:hover {
					&:not(.active) {
						background:var(--table-even) !important;
					}

					box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
				}
			}
			&:hover {
			 	background: $green-itech-light;
				color: $white;
			}

			td:has(button.btn-itech-icon) {
				padding: 0 !important;
				background: black !important;
			}

			td:has(div) {
				& > div {
					background: transparent;
				}
			}
			th {
				cursor:pointer;
				color:var(--text-color-link);
				text-decoration:underline;
				padding: 5px;
				text-align: left;
				// font-weight: 1000 !important;
				font-size: 0.85rem;
			   }
		}
		th.active {
			background: var(--form-background-color-focus);
		}
	}
}

.table-striped {
	thead th {
		border-top: 2px;
		border-color: var(--border-color);
		background-color: white;
	}
	tbody {
		tr {
			border-top: 2px;
			&:nth-of-type(odd) {
				background: var(--table-odd);

				&:hover {
					&:not(.active) {
						background: var(--main-color-dark) !important;
						border: red;
						color: aliceblue;
						* {
							// color: var(--default-text) !important;
							color: white;
						}
					}

					// box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
				}
			}

			&:nth-of-type(even) {
				&:not(.active) {
					background:var(--table-even) !important;
				}

				&:hover {
					&:not(.active) {
						background:var(--main-color-dark) !important;
						* {
							// color: var(--default-text) !important;
							color: white;
						}
					}

					// box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
				}
			}

			/*&:hover {
				background: $green-itech-light;
				color: $white;
				box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.2);
			}*/

			td {
				//text-align: center;
				border-color: #B9B9B9;
			}

			td:has(button.btn-itech-icon) {
				padding: 0 !important;
				background: black !important;
			}

			td:has(div) {
				& > div {
					background: transparent;
				}
			}
		}

		// &:hover {
		//     background: $green-itech-light;
		//     color: $white;
		// }
	}
}

.hide-bulk-actions {
	[data-test="bulk-actions-toolbar"] {
		display: none;
	}
}

.table-vertical-divider {
	position:relative;
    left:0%;
    top:0%;
    bottom:0%;
    border-left:1px solid lightgrey;
}

.table thead.table-itech-thread th, .table thead.table-itech-thread td, .table thead.table-itech-thread span {
	background: white!important;
}
.table {
	background: #FFFFFF;
}