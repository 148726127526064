@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
.public-DraftEditorPlaceholder-inner {
    display: none;
}
/*
 .RichEditor-editor {
 border-top: 1px solid #ddd;
 cursor: text;
 font-size: 16px;
 margin-top: 10px;
 .public-DraftEditorPlaceholder-root {
 margin: 0 -15px -15px;
 padding: 15px;
 }
 .public-DraftEditor-content {
 margin: 0 -15px -15px;
 padding: 15px;
 min-height: 100px;
 }
 }
 .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
 display: none;
 }
 .RichEditor-editor {
 .RichEditor-blockquote {
 border-left: 5px solid #eee;
 color: #666;
 font-family: 'Hoefler Text', 'Georgia', serif;
 font-style: italic;
 margin: 16px 0;
 padding: 10px 20px;
 }
 .public-DraftStyleDefault-pre {
 background-color: rgba(0, 0, 0, 0.05);
 font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
 font-size: 16px;
 padding: 20px;
 }
 }
 .RichEditor-controls {
 font-family: 'Helvetica', sans-serif;
 font-size: 14px;
 margin-bottom: 5px;
 user-select: none;
 }
 .RichEditor-styleButton {
 color: #999;
 cursor: pointer;
 margin-right: 16px;
 padding: 2px 0;
 display: inline-block;
 }
 .RichEditor-activeButton {
 color: #5890ff;
 }
 .draftJsToolbar__buttonWrapper__1Dmqh {
 display: inline-block;
 }
 .draftJsToolbar__button__qi1gf {
 background: #fbfbfb;
 color: #888;
 font-size: 18px;
 border: 0;
 padding-top: 5px;
 vertical-align: bottom;
 height: 34px;
 width: 36px;
 svg {
 fill: #888;
 }
 &:hover, &:focus {
 background: #f3f3f3;
 outline: 0;
 !* reset for :focus *!
 }
 }
 .draftJsToolbar__active__3qcpF {
 background: #efefef;
 color: #444;
 svg {
 fill: #444;
 }
 }
 .draftJsToolbar__separator__3U7qt {
 display: inline-block;
 border-right: 1px solid #ddd;
 height: 24px;
 margin: auto 0.5em;
 }
 .draftJsToolbar__toolbar__dNtBH {
 border: 1px solid #ddd;
 background: #fff;
 border-radius: 2px;
 box-shadow: 0 1px 3px 0 rgba(220, 220, 220, 1);
 z-index: 2;
 box-sizing: border-box;
 display: flex;
 &:after {
 border-color: rgba(255, 255, 255, 0);
 border-top-color: #fff;
 border-width: 4px;
 margin-left: -4px;
 }
 &:before {
 border-color: rgba(221, 221, 221, 0);
 border-top-color: #ddd;
 border-width: 6px;
 margin-left: -6px;
 }
 }
 .draftJsUndoPlugin__button__3Vugn {
 box-sizing: border-box;
 border: 1px solid #ddd;
 height: 1.5em;
 color: #888;
 border-radius: 1.5em;
 line-height: 1.2em;
 cursor: pointer;
 background-color: #fff;
 width: 2.5em;
 font-weight: bold;
 font-size: 1em;
 padding: 0;
 margin: auto .25em;
 &:focus {
 background-color: #eee;
 color: #999;
 outline: 0;
 !* reset for :focus *!
 }
 &:hover {
 background-color: #eee;
 color: #999;
 }
 &:active {
 background-color: #ddd;
 color: #777;
 }
 &:disabled {
 background-color: #F5F5F5;
 color: #ccc;
 }
 }*/
.template-create-form {
    > div {
        &:first-child {
            flex: 1;
        }
    }
}

.template-conclusion {
    height: 125px;
    width: 100%;
}

.report-content {
    // min-height: 350px;
    height: 400px;
    width: 100%;
}

.template-body {
    min-height: 400px;
    width: 100%;
}

.rdw-option-wrapper {
    border: 1px solid #f1f1f1;
    padding: 5px;
    min-width: 25px;
    height: 20px;
    border-radius: 2px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;

    &:hover {
        box-shadow: 1px 1px 0 #bfbdbd;
    }

    &:active {
        box-shadow: 1px 1px 0 #bfbdbd inset;
    }
}

.rdw-option-active {
    box-shadow: 1px 1px 0 #bfbdbd inset;
}

.rdw-option-disabled {
    opacity: 0.3;
    cursor: default;
}

.rdw-dropdown-wrapper {
    height: 30px;
    background: white;
    cursor: pointer;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    margin: 0 3px;
    text-transform: capitalize;

    &:focus {
        outline: none;
    }

    &:hover {
        box-shadow: 1px 1px 0 #bfbdbd;
        background-color: #fff;
    }

    &:active {
        box-shadow: 1px 1px 0 #bfbdbd inset;
    }
}

.rdw-dropdown-carettoopen {
    height: 0;
    width: 0;
    position: absolute;
    top: 35%;
    right: 10%;
    border-top: 6px solid black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

.rdw-dropdown-carettoclose {
    height: 0;
    width: 0;
    position: absolute;
    top: 35%;
    right: 10%;
    border-bottom: 6px solid black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

.rdw-dropdown-selectedtext {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    padding: 0 5px;
}

.rdw-dropdown-optionwrapper {
    z-index: 100;
    position: relative;
    border: 1px solid #f1f1f1;
    width: 98%;
    background: white;
    border-radius: 2px;
    margin: 0;
    padding: 0;
    max-height: 250px;
    overflow-y: scroll;

    &:hover {
        box-shadow: 1px 1px 0 #bfbdbd;
        background-color: #fff;
    }
}

.rdw-dropdownoption-default {
    min-height: 25px;
    display: flex;
    align-items: center;
    padding: 0 5px;
}

.rdw-dropdownoption-highlighted {
    background: #f1f1f1;
}

.rdw-dropdownoption-active {
    background: #f5f5f5;
}

.rdw-dropdownoption-disabled {
    opacity: 0.3;
    cursor: default;
}

.rdw-inline-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}

.rdw-inline-dropdown {
    width: 50px;
}

.rdw-inline-dropdownoption {
    height: 40px;
    display: flex;
    justify-content: center;
}

.rdw-block-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}

.rdw-block-dropdown {
    width: 110px;
}

.rdw-fontsize-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}

.rdw-fontsize-dropdown {
    min-width: 40px;
}

.rdw-fontsize-option {
    display: flex;
    justify-content: center;
}

.rdw-fontfamily-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}

.rdw-fontfamily-dropdown {
    width: 115px;
}

.rdw-fontfamily-placeholder {
    white-space: nowrap;
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rdw-fontfamily-optionwrapper {
    width: 150px;
}

.rdw-list-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}

.rdw-list-dropdown {
    width: 50px;
    z-index: 90;
}

.rdw-list-dropdownOption {
    height: 40px;
    display: flex;
    justify-content: center;
}

.rdw-text-align-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}

.rdw-text-align-dropdown {
    width: 50px;
    z-index: 90;
}

.rdw-text-align-dropdownOption {
    height: 40px;
    display: flex;
    justify-content: center;
}

.rdw-right-aligned-block {
    text-align: right;

    > div {
        text-align: inherit;
    }
}

.rdw-left-aligned-block {
    text-align: left !important;

    > div {
        text-align: inherit;
    }
}

.rdw-center-aligned-block {
    text-align: center !important;

    > div {
        text-align: inherit;
    }
}

.rdw-justify-aligned-block {
    text-align: justify !important;

    > div {
        text-align: inherit;
    }
}

.rdw-colorpicker-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}

.rdw-colorpicker-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 175px;
    height: 175px;
    border: 1px solid #f1f1f1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-colorpicker-modal-header {
    display: flex;
    padding-bottom: 5px;
}

.rdw-colorpicker-modal-style-label {
    font-size: 15px;
    width: 50%;
    text-align: center;
    cursor: pointer;
    padding: 0 10px 5px;
}

.rdw-colorpicker-modal-style-label-active {
    border-bottom: 2px solid #0a66b7;
}

.rdw-colorpicker-modal-options {
    margin: 5px auto;
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    overflow: scroll;
}

.rdw-colorpicker-cube {
    width: 22px;
    height: 22px;
    border: 1px solid #f1f1f1;
}

.rdw-colorpicker-option {
    margin: 3px;
    padding: 0;
    min-height: 20px;
    border: none;
    width: 22px;
    height: 22px;
    min-width: 22px;
    box-shadow: 1px 2px 1px #bfbdbd inset;

    &:hover {
        box-shadow: 1px 2px 1px #bfbdbd;
    }

    &:active {
        box-shadow: -1px -2px 1px #bfbdbd;
    }
}

.rdw-colorpicker-option-active {
    box-shadow: 0 0 2px 2px #bfbdbd;
}

.rdw-link-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}

.rdw-link-dropdown {
    width: 50px;
}

.rdw-link-dropdownOption {
    height: 40px;
    display: flex;
    justify-content: center;
}

.rdw-link-dropdownPlaceholder {
    margin-left: 8px;
}

.rdw-link-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 205px;
    border: 1px solid #f1f1f1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-link-modal-label {
    font-size: 15px;
}

.rdw-link-modal-input {
    margin-top: 5px;
    border-radius: 2px;
    border: 1px solid #f1f1f1;
    height: 25px;
    margin-bottom: 15px;
    padding: 0 5px;

    &:focus {
        outline: none;
    }
}

.rdw-link-modal-buttonsection {
    margin: 0 auto;
}

.rdw-link-modal-target-option {
    margin-bottom: 20px;

    > span {
        margin-left: 5px;
    }
}

.rdw-link-modal-btn {
    margin-left: 10px;
    width: 75px;
    height: 30px;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    cursor: pointer;
    background: white;
    text-transform: capitalize;

    &:hover {
        box-shadow: 1px 1px 0 #bfbdbd;
    }

    &:active {
        box-shadow: 1px 1px 0 #bfbdbd inset;
    }

    &:focus {
        outline: none !important;
    }

    &:disabled {
        background: #ece9e9;
    }
}

.rdw-link-dropdownoption {
    height: 40px;
    display: flex;
    justify-content: center;
}

.rdw-history-dropdown {
    width: 50px;
    width: 50px;
}

.rdw-embedded-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}

.rdw-embedded-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 180px;
    border: 1px solid #f1f1f1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    justify-content: space-between;
    box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-embedded-modal-header {
    font-size: 15px;
    display: flex;
}

.rdw-embedded-modal-header-option {
    width: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.rdw-embedded-modal-header-label {
    width: 95px;
    border: 1px solid #f1f1f1;
    margin-top: 5px;
    background: #6eb8d4;
    border-bottom: 2px solid #0a66b7;
}

.rdw-embedded-modal-link-section {
    display: flex;
    flex-direction: column;
}

.rdw-embedded-modal-link-input {
    width: 88%;
    height: 35px;
    margin: 10px 0;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    font-size: 15px;
    padding: 0 5px;

    &:focus {
        outline: none;
    }
}

.rdw-embedded-modal-link-input-wrapper {
    display: flex;
    align-items: center;
}

.rdw-embedded-modal-btn-section {
    display: flex;
    justify-content: center;
}

.rdw-embedded-modal-btn {
    margin: 0 3px;
    width: 75px;
    height: 30px;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    cursor: pointer;
    background: white;
    text-transform: capitalize;

    &:hover {
        box-shadow: 1px 1px 0 #bfbdbd;
    }

    &:active {
        box-shadow: 1px 1px 0 #bfbdbd inset;
    }

    &:focus {
        outline: none !important;
    }

    &:disabled {
        background: #ece9e9;
    }
}

.rdw-embedded-modal-size {
    align-items: center;
    display: flex;
    margin: 8px 0;
    justify-content: space-between;
}

.rdw-embedded-modal-size-input {
    width: 80%;
    height: 20px;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    font-size: 12px;

    &:focus {
        outline: none;
    }
}

.rdw-emoji-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}

.rdw-emoji-modal {
    overflow: auto;
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-wrap: wrap;
    width: 235px;
    height: 180px;
    border: 1px solid #f1f1f1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-emoji-icon {
    margin: 2.5px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rdw-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    > div {
        width: 12px;
        height: 12px;
        background-color: #333;
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .rdw-bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .rdw-bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
}

.rdw-image-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}

.rdw-image-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 235px;
    border: 1px solid #f1f1f1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-image-modal-header {
    font-size: 15px;
    margin: 10px 0;
    display: flex;
}

.rdw-image-modal-header-option {
    width: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.rdw-image-modal-header-label {
    width: 80px;
    background: #f1f1f1;
    border: 1px solid #f1f1f1;
    margin-top: 5px;
}

.rdw-image-modal-header-label-highlighted {
    background: #6eb8d4;
    border-bottom: 2px solid #0a66b7;
}

.rdw-image-modal-upload-option {
    width: 100%;
    color: gray;
    cursor: pointer;
    display: flex;
    border: none;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    outline: 2px dashed gray;
    outline-offset: -10px;
    margin: 10px 0;
    padding: 9px 0;
}

.rdw-image-modal-upload-option-highlighted {
    outline: 2px dashed #0a66b7;
}

.rdw-image-modal-upload-option-label {
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;

    span {
        padding: 0 20px;
    }
}

.rdw-image-modal-upload-option-image-preview {
    max-width: 100%;
    max-height: 200px;
}

.rdw-image-modal-upload-option-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.rdw-image-modal-url-section {
    display: flex;
    align-items: center;
}

.rdw-image-modal-url-input {
    width: 90%;
    height: 35px;
    margin: 15px 0 12px;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    font-size: 15px;
    padding: 0 5px;

    &:focus {
        outline: none;
    }
}

.rdw-image-modal-btn-section {
    margin: 10px auto 0;
}

.rdw-image-modal-btn {
    margin: 0 5px;
    width: 75px;
    height: 30px;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    cursor: pointer;
    background: white;
    text-transform: capitalize;

    &:hover {
        box-shadow: 1px 1px 0 #bfbdbd;
    }

    &:active {
        box-shadow: 1px 1px 0 #bfbdbd inset;
    }

    &:focus {
        outline: none !important;
    }

    &:disabled {
        background: #ece9e9;
    }
}

.rdw-image-modal-spinner {
    position: absolute;
    top: -3px;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
}

.rdw-image-modal-alt-input {
    width: 70%;
    height: 20px;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    font-size: 12px;
    margin-left: 5px;

    &:focus {
        outline: none;
    }
}

.rdw-image-modal-alt-lbl {
    font-size: 12px;
}

.rdw-image-modal-size {
    align-items: center;
    display: flex;
    margin: 8px 0;
    justify-content: space-between;
}

.rdw-image-modal-size-input {
    width: 40%;
    height: 20px;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    font-size: 12px;

    &:focus {
        outline: none;
    }
}

.rdw-image-mandatory-sign {
    color: red;
    margin-left: 3px;
    margin-right: 3px;
}

.rdw-remove-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}

.rdw-history-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}

.rdw-history-dropdownoption {
    height: 40px;
    display: flex;
    justify-content: center;
}

.rdw-link-decorator-wrapper {
    position: relative;
}

.rdw-link-decorator-icon {
    position: absolute;
    left: 40%;
    top: 0;
    cursor: pointer;
    background-color: white;
}

.rdw-mention-link {
    text-decoration: none;
    color: #1236ff;
    background-color: #f0fbff;
    padding: 1px 2px;
    border-radius: 2px;
}

.rdw-suggestion-wrapper {
    position: relative;
}

.rdw-suggestion-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    border: 1px solid #f1f1f1;
    min-width: 100px;
    max-height: 150px;
    overflow: auto;
    background: white;
    z-index: 100;
}

.rdw-suggestion-option {
    padding: 7px 5px;
    border-bottom: 1px solid #f1f1f1;
}

.rdw-suggestion-option-active {
    background-color: #f1f1f1;
}

.rdw-hashtag-link {
    text-decoration: none;
    color: #1236ff;
    background-color: #f0fbff;
    padding: 1px 2px;
    border-radius: 2px;
}

.rdw-image-alignment-options-popup {
    position: absolute;
    background: white;
    display: flex;
    padding: 5px 2px;
    border-radius: 2px;
    border: 1px solid #f1f1f1;
    width: 105px;
    cursor: pointer;
    z-index: 100;
}

.rdw-alignment-option-left {
    justify-content: flex-start;
}

.rdw-image-alignment-option {
    height: 15px;
    width: 15px;
    min-width: 15px;
}

.rdw-image-alignment {
    position: relative;
}

.rdw-image-imagewrapper {
    position: relative;
}

.rdw-image-center {
    display: flex;
    justify-content: center;
}

.rdw-image-left {
    display: flex;
}

.rdw-image-right {
    display: flex;
    justify-content: flex-end;
}

.rdw-image-alignment-options-popup-right {
    right: 0;
}

.rdw-editor-main {
    height: 100%;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;

    blockquote {
        border-left: 5px solid #f1f1f1;
        padding-left: 5px;
    }

    pre {
        background: #f1f1f1;
        border-radius: 3px;
        padding: 1px 10px;
    }
}

.rdw-editor-main.toolbar-auto-hide {
    position: absolute;
    top: 0;
}

.rdw-editor-toolbar {
    margin: -9px;
    padding: 6px 5px 0;
    border-radius: 2px;
    border: 1px solid var(--border-color);
    display: flex;
    justify-content: flex-start;
    background: var(--report-background-color);
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
    user-select: none;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.rdw-editor-toolbar.toolbar-auto-hide {
    position: absolute;
    z-index: 2;
    transform: translateY(-100%);
    color: #000000;
}

.public-DraftStyleDefault-block {
    margin: .3em 0;
}

.rdw-editor-wrapper {
    &:focus {
        outline: none;
    }

    position: relative;
    box-sizing: content-box;
    height: 100%;
}

.report-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
}

.report-template {
    width: 200px;
    padding: 0.5rem;
    height: 100%;
}

.RichEditor-root {
    background: var(--report-background-color) !important;
    color: var(--text-color);
    // border: 1px solid #ddd;
    //font-family: 'Times Newer Roman', serif;
    font-size: 14px;
    padding: 0.5rem;
    flex-grow: 1;
    flex-basis: 0;
}
