.detailed-export-filter-box-button {
    &> .col-form-label {
        padding-bottom: 0;
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
    }
}
.pad-bottom {
    padding-bottom: 10px;
}
.detailed-export-form-group {
    margin-bottom: 0;
}
.detailed-export-date-picking {
    min-width: 500px;
}