@import "dashboard";
@import "detailed-export";
@import "home";
@import "order";
@import "templates";
@import "studies";
@import "report";
@import "button-vietrad";
@import "vu-meter";
@import "upload";
@import "button";

.main-page {
	display: flex;
	flex-direction: column;
    width: 100%;
    background-color: var(--main-background);
    color: var(--text-color);
}

.home-page {
	position: relative;
	display: flex;
	//min-height: 100%;
	//width: 100%;
	flex: auto;
}

.checkbox-group-css{
    min-width: 120px;
    margin-right: 6px;
    display: flex;
    flex-wrap: wrap;
}

.group-checkbox-group-css{
    flex-grow: 1;
    > div {
        &:first-child {
            display: flex;
            justify-content: space-between;
        }
    }
}

.custom-group-mydatepicking {
    .custom-group-btn-pick-date {
        display: flex;
        // width: 100%;
        justify-content: space-between;
        & > button {
            // min-width: 80px;
            margin-left: .15rem;
            margin-right: .15rem;
            flex-grow: 1;
    
            &:first-child {
                margin-left: 0 !important;
            }
    
            &:last-child {
                margin-right: 0 !important;
            }
        }
    }

    .custom-input-btn-pick-date {
        & > div:first-child {
            padding-right: 5px !important;
            padding-left: 0px !important;
        }

        & > div:last-child {
            padding-right: 0px !important;
            padding-left: 5px !important;
        }
    }
}

// hide active keyboard-selected
.react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
    color: black;
  }

#none-dropdown-toggle {
    &.dropdown-toggle:after{
        content: none;
    }
    border: none;
}

.topbar-main {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    margin: auto;
}
.topbar-tab-main {
    margin-bottom: -2px;
    position: relative;
    // z-index: 2;
}

.top-height {
    height: var(--top-height);
}

.nav-top-height {
    height: calc(var(--top-height) - var(--tab-height));
    background-color: #333547;
}

.nav-tab {
    height: var(--tab-height);
    // background-color: var(--nav-tab-background) !important;
    padding-top: 1px;
    border-bottom: 3px solid var(--main-color-dark)
}

.nav-right {
    position: absolute;
    left: 100%;
    transform: translateX(-100%);
    padding-right: 15px;
    align-items: center;
    z-index: 200; // fix display css
}

.font-13px{
    font-size: 13px !important;
}

.react-datepicker-popper{
    z-index: 3;
}

.font-25px{
    font-size: 25px !important;
}

.font-20px{
    font-size: 20px !important;
}

.font-18px { 
    font-size: 18px !important;
}

.line-height-1{
    line-height: 1;
}

.h-90{
    height: 90% !important;
}

.logo-hospital {
    // background-image: url('/images/logo-hospital.svg');
    // background-position: center;
    height: 50px;
    width: 200px;
    background-repeat: no-repeat;
	background-size: contain;
}

.bordered {
    border: 1px solid var(--border-color);
}

.bordered-x {
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
}

.bordered-y {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
}

.bordered-right {
    border-right: 1px solid var(--border-color);
}

.bordered-left {
    border-left: 1px solid var(--border-color);
}

.bordered-bottom {
    border-bottom: 1px solid var(--border-color);
}

.bordered-top {
    border-top: 1px solid var(--border-color);
}

.border-radius-0 {
    border-radius: 0 !important;
}

.form-control {
    padding: inherit;
    padding-left: 8px;
    padding-right: 8px;
}

.font-normal {
    font-size: 13px;
}

.btn-itech-primary {
    background-color: var(--primary-color)!important;
}

.custom-radio input[type=radio]:checked + label:after {
    top: 6px;
}
